/* eslint-disable prefer-const */
import Stripe from 'stripe'
import { PaymentMethodID } from 'components/Checkout2023/types'
import { LineItem, NewCartStore } from 'cart'
import { CalculateTax, FullStripeAddress, StripeCheckout } from 'stripe_lib/types'
import { STRIPE_PRODUCTS } from 'stripe_lib/products'
import { TransactionModel } from 'avatax/lib/models'
import { klaviyoTrackStripePurchaseComplete } from 'events/klaviyo'
import { isMembershipVariantId, isPodCoverProductId, stripeAccessoryIds, stripeMattressIds, stripePodCoverIds, stripePodCoverUpgradeIds } from 'products/utils'
import { amCompletePaymentMethod, amCompleteWebviewPurchase, amplitudeThankYou } from 'events/amplitude'
import { AFFIRM_US_JS, AFFIRM_US_PUBLIC_KEY } from 'stripe_lib'
import { PriceManager } from 'prices'
import { RegionShort } from 'utils/internationalization'
import { MembershipProducts } from 'products/allProducts'
import shippingTimelines, { getAllShippingTexts } from 'config/shipping_timelines'
import { getSubscriptionCode } from 'eight_api/subscription'
import { NON_DISPLAYING_COUPONS } from 'stripe_lib/discounts'
import { datadogReportAPICall, datadogReportEvent } from 'events/datadog'

export const addRequiredLineItemAttributes = async (lineItems: LineItem[]) => {
	const lineItemUpdated: LineItem[] = []
	for (const lineItem of lineItems) {
		const variantData = shippingTimelines[lineItem.variantId]
		const timeline = JSON.stringify(variantData)
		const humanReadable = JSON.stringify(getAllShippingTexts(variantData))

		if (timeline) {
			lineItem.attributes.push({ key: 'shippingTimeline', value: timeline })
			if (humanReadable) {
				lineItem.attributes.push({ key: 'shippingTimeline2', value: humanReadable })
			}
		}

		const isMembership = isMembershipVariantId(lineItem.variantId)
		if (isMembership) {
			let hasCode = false
			for (const attribute of lineItem.attributes) {
				if (attribute.key === 'code') {
					hasCode = true
					break
				}
			}

			if (!hasCode) {
				lineItem.attributes.push({ key: 'activation_code', value: await getSubscriptionCode() })
			}
		}

		lineItemUpdated.push(lineItem)
	}

	return lineItemUpdated
}

export const formatInvoiceCoupons = (invoice: Stripe.Invoice) => {
	return [...new Set(invoice.lines.data.flatMap((it) => it.discounts.filter((d: Stripe.Discount) => !!d.coupon).map((d: Stripe.Discount) => d.coupon.id)))].join(',')
}

export const getCheckoutDataFromURL = async (region: RegionShort) => {
	const url = new URL(window.location.href)
	const coverProduct = STRIPE_PRODUCTS[url.searchParams.get('product')]
	const subscriptionId = url.searchParams.get('subscription')
	const membershipProduct = STRIPE_PRODUCTS[subscriptionId]
	const coupons = url.searchParams.get('discount')

	if (coverProduct && membershipProduct && coupons) {
		const lines = [
			{
				price: coverProduct,
				quantity: 1,
				attributes: [], // how to populate this?
			},
			{
				price: membershipProduct,
				quantity: 1,
				attributes: subscriptionId.includes(MembershipProducts.AutopilotEnhanced.id as string) ? [{ key: 'warranty', value: 'extended warranty' }] : [],
			},
		]

		const mattressProduct = STRIPE_PRODUCTS[url.searchParams.get('mattress')]
		if (mattressProduct) {
			lines.push({
				price: mattressProduct,
				quantity: 1,
				attributes: [], // how to populate this?
			})
		}

		const accessoryProduct = STRIPE_PRODUCTS[url.searchParams.get('accessory')]
		if (url.searchParams.get('accessory') && accessoryProduct) {
			lines.push({
				price: accessoryProduct,
				quantity: 1,
				attributes: [], // how to populate this?
			})
		}

		const legKit = STRIPE_PRODUCTS[url.searchParams.get('legkit')]
		if (url.searchParams.get('legkit') && legKit) {
			lines.push({
				price: legKit,
				quantity: 1,
				attributes: [], // how to populate this?
			})
		}

		const email = url.searchParams.get('email')
		const r = region || 'us'

		const updatedLines = await addRequiredLineItemAttributes(lines.map((it) => ({ ...it, variantId: it.price })))
		return {
			coupons: coupons ? coupons.toUpperCase().split(',') : [],
			currency: PriceManager.getRegionCurrency(r),
			region: r,
			email,
			lines: updatedLines.map((it) => ({ ...it, price: it.variantId })),
		}
	}

	return null
}

export function kyTrackLatestCart(invoice: Stripe.UpcomingInvoice) {
	if (!invoice) {
		return
	}

	// TODO cart abandomnet issues

	// const savedCardId = sessionStorage.getItem('klaviyo_cart_id')
	// const cartId = invoice.id
	//
	// if (cartId !== savedCardId) {
	// 	sessionStorage.setItem('klaviyo_cart_id', cartId)
	// 	const props: any = {
	// 		last_cart: invoice,
	// 		last_cart_has_pod: hasPodProduct(invoice),
	// 		last_cart_has_cover: hasCoverProduct(invoice),
	// 	}
	// 	if (hasPodProduct(invoice) || hasCoverProduct(invoice)) {
	// 		const item = hasPodProduct(invoice) || hasCoverProduct(invoice)
	// 		props.pod_model_name = (item.price.product as Stripe.Product).name
	// 		props.pod_model_size = item.price.nickname
	// 	}
	//
	// 	kySetUserProperties(props)
	// }
}

export const parseShippingTimeline = (invoice: Stripe.UpcomingInvoice, cartStore?: NewCartStore) => {
	// If there's a membership item, then the shipping timeline is lying on the metadata of that subscription item.
	// Otherwise, it's on the invoice.

	let shippingTimeline = ''

	if (invoice) {
		if ('shippingTimeline3' in invoice.metadata) {
			return 'Ships ' + invoice.metadata['shippingTimeline3']
		}

		const itemsWithTimeline = invoice.lines.data.filter((line) => line.metadata['shippingTimeline'])
		const podInOrder = itemsWithTimeline.find((it) => isPodCoverProductId((it.price.product as Stripe.Product).id))
		if (itemsWithTimeline.length) {
			const key = Object.keys(JSON.parse(itemsWithTimeline[0].metadata['shippingTimeline'])).find((k) => k.startsWith('minDays'))
			const longest = itemsWithTimeline.reduce((prev, curr) => {
				return parseInt(JSON.parse(prev.metadata['shippingTimeline'])[key]) < parseInt(JSON.parse(curr.metadata['shippingTimeline'])[key]) ? curr : prev
			})

			shippingTimeline = longest.metadata['shippingTimeline2']

			if (podInOrder) {
				shippingTimeline = podInOrder.metadata['shippingTimeline2']
			}
		} else {
			shippingTimeline = invoice.metadata['shippingTimeline2']
		}
	} else if (cartStore) {
		for (const item of cartStore.cart.lineItems) {
			for (const attr of item.attributes) {
				if (attr.key === 'shippingTimeline2') {
					shippingTimeline = attr.value
					break
				}
			}
		}
	}

	if (!shippingTimeline) return ''
	const jsonTimeline: Record<string, string> = JSON.parse(shippingTimeline)
	return Object.values(jsonTimeline)[0].replace('.', '')
}

export const methodIsFinancing = (method: PaymentMethodID) => {
	return method === 'affirm' //|| method === 'klarna'
}

export const methodIsOneClick = (method: PaymentMethodID) => {
	return method === 'google-pay' || method === 'apple-pay'
}

export const getLineItemAmount = (lineItem: Stripe.InvoiceLineItem, taxGroup?: TransactionModel) => {
	const discount = lineItem.discount_amounts.reduce((acc, it) => acc + it.amount, 0)
	let tax = 0
	if (taxGroup) {
		const taxLines = taxGroup.lines || []
		const taxLine = taxLines.find((it) => it.itemCode.includes(lineItem.price.id))
		tax = taxLine ? taxLine.tax * 100 : 0
	}

	return lineItem.amount - discount + tax
}

export const calculateMinMaxDays = (invoice: Stripe.UpcomingInvoice) => {
	if (!invoice) {
		return { minDays: 0, maxDays: 0 }
	}

	let minDays = 1000
	let maxDays = 0
	for (const item of invoice.lines.data) {
		const shippingTimelineOne = item.metadata['shippingTimeline']
		if (shippingTimelineOne) {
			const parsed = JSON.parse(shippingTimelineOne)

			// TODO handle multiple regions
			const values = Object.values(parsed).map((it: string) => parseInt(it.replace('.', '')))

			minDays = Math.min(minDays, Math.min(...values))
			maxDays = Math.max(maxDays, Math.max(...values))
		}
	}

	return { minDays, maxDays }
}

export const createEstimate = async (region: RegionShort, email?: string, discountCode?: string) => {
	try {
		const stripeData = (await getCheckoutDataFromURL(region)) ?? JSON.parse(localStorage.getItem('stripeInvoice'))
		if (!stripeData) return null

		if (discountCode) {
			stripeData['coupons'] = discountCode?.split(',') ?? ''
		}

		if (!stripeData.currency) {
			stripeData.currency = PriceManager.getRegionCurrency(region)
		}

		if (!stripeData.shipping) {
			stripeData.shipping = {}
		}

		if (email) {
			stripeData.shipping.email = email
		}

		const rawResponse = await fetch('/api/estimate_invoice/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(stripeData),
		})

		const json = await rawResponse.json()
		datadogReportAPICall(rawResponse.status, 'estimate_invoice_2')
		datadogReportEvent('success', 'estimate_invoice_2')
		if (json.success) {
			localStorage.setItem('stripeInvoice', JSON.stringify(stripeData))
			if (stripeData.email) json.invoice.customer_email = stripeData.email
			return json.invoice as Stripe.UpcomingInvoice
		}
		return null
	} catch (e) {
		datadogReportEvent('failure', 'estimate_invoice', e)
		throw new Error('Could not create estimate from LocalStorage data: ' + e.message)
	}
}

export const swapInvoiceToFinancing = () => {
	let swapped = false
	try {
		const stripeData = JSON.parse(localStorage.getItem('stripeInvoice')) as StripeCheckout
		const proYearly = stripeData.lines.find((lineItem) => lineItem.price === STRIPE_PRODUCTS.membership_pro__yearly)
		const plusYearly = stripeData.lines.find((lineItem) => lineItem.price === STRIPE_PRODUCTS.membership_plus__yearly)
		const autopilotStandard = stripeData.lines.find((lineItem) => lineItem.price === STRIPE_PRODUCTS.autopilot_standard__yearly)
		const autopilotEnhanced = stripeData.lines.find((lineItem) => lineItem.price === STRIPE_PRODUCTS.autopilot_enhanced__yearly)
		if (proYearly) {
			proYearly.price = STRIPE_PRODUCTS.membership_pro__yearly_financing
			swapped = true
		}
		if (plusYearly) {
			plusYearly.price = STRIPE_PRODUCTS.membership_plus__yearly_financing
			swapped = true
		}
		if (autopilotStandard) {
			autopilotStandard.price = STRIPE_PRODUCTS.autopilot_standard__yearly_financing
			swapped = true
		}
		if (autopilotEnhanced) {
			autopilotEnhanced.price = STRIPE_PRODUCTS.autopilot_enhanced__yearly_financing
			swapped = true
		}
		localStorage.setItem('stripeInvoice', JSON.stringify(stripeData))
	} catch (e) {
		throw new Error('Error modifying LocalStorage data: ' + e.message)
	}
	return swapped
}

export const swapInvoiceToRecurring = () => {
	let swapped = false
	try {
		const stripeData = JSON.parse(localStorage.getItem('stripeInvoice')) as StripeCheckout
		const proFinancing = stripeData.lines.find((lineItem) => lineItem.price === STRIPE_PRODUCTS.membership_pro__yearly_financing)
		const plusFinancing = stripeData.lines.find((lineItem) => lineItem.price === STRIPE_PRODUCTS.membership_plus__yearly_financing)
		const autopilotStandardFinancing = stripeData.lines.find((lineItem) => lineItem.price === STRIPE_PRODUCTS.autopilot_standard__yearly_financing)
		const autopilotEnhancedFinancing = stripeData.lines.find((lineItem) => lineItem.price === STRIPE_PRODUCTS.autopilot_enhanced__yearly_financing)
		if (proFinancing) {
			proFinancing.price = STRIPE_PRODUCTS.membership_pro__yearly
			swapped = true
		}
		if (plusFinancing) {
			plusFinancing.price = STRIPE_PRODUCTS.membership_plus__yearly
			swapped = true
		}
		if (autopilotStandardFinancing) {
			autopilotStandardFinancing.price = STRIPE_PRODUCTS.autopilot_standard__yearly
			swapped = true
		}
		if (autopilotEnhancedFinancing) {
			autopilotEnhancedFinancing.price = STRIPE_PRODUCTS.autopilot_enhanced__yearly
			swapped = true
		}
		localStorage.setItem('stripeInvoice', JSON.stringify(stripeData))
	} catch (e) {
		throw new Error('Error modifying LocalStorage data: ' + e.message)
	}
	return swapped
}

export const getTaxData = (shippingAddress: FullStripeAddress, currency: string, invoiceLines: Stripe.InvoiceLineItem[], customerId: string) => {
	const taxData: CalculateTax = {
		// TODO check customer code spec
		currency: currency,
		customerId: customerId,
		address: shippingAddress ? shippingAddress.address : null,
		lines: invoiceLines.map((line) => {
			return {
				amount: getLineItemAmount(line),
				quantity: line.quantity,
				taxBehavior: line.price.tax_behavior,
				description: line.description,
				itemCode: line.price.id,
				taxCode: (line.price.product as Stripe.Product).tax_code as Stripe.TaxCode,
			}
		}),
	}
	return taxData
}

export const affirmTracking = (invoice: Stripe.Invoice) => {
	const _affirm_config = {
		public_api_key: AFFIRM_US_PUBLIC_KEY,
		script: AFFIRM_US_JS,
		session_id: 'affirm_client_session_id',
	}

	const initialize = function (l, g, m, e, a, f, b) {
		let d,
			c = l[m] || {},
			h = document.createElement(f),
			n = document.getElementsByTagName(f)[0],
			k = function (a, b, c) {
				return function () {
					a[b]._.push([c, arguments])
				}
			}
		c[e] = k(c, e, 'set')
		d = c[e]
		c[a] = {}
		c[a]._ = []
		d._ = []
		c[a][b] = k(c, a, b)
		a = 0
		for (b = 'set add save post open empty reset on off trigger ready setProduct'.split(' '); a < b.length; a++) d[b[a]] = k(c, e, b[a])
		a = 0
		for (b = ['get', 'token', 'url', 'items']; a < b.length; a++) d[b[a]] = function () {}
		h.async = !0
		h.src = g[f]
		n.parentNode.insertBefore(h, n)
		delete g[f]
		d(g)
		l[m] = c
	}

	initialize(window, _affirm_config, 'affirm', 'checkout', 'ui', 'script', 'ready')

	affirm.ui.ready(function () {
		affirm.analytics.trackOrderConfirmed(
			{
				orderId: invoice.id,
				total: invoice.total / 100,
			},
			invoice.lines.data.map((it) => {
				const product = it.price.product as Stripe.Product
				return {
					productId: product.id,
					quantity: it.quantity,
				}
			})
		)
	})
}

export const twitterPurchaseTracking = (invoice: Stripe.Invoice) => {
	if (!window.twq) return

	const lineItems = []
	invoice.lines.data.forEach((item) => {
		lineItems.push({
			product_id: item.price.id,
			variant_id: item.price.lookup_key,
			name: item.description,
			sku: item.price.metadata?.sku,
			variant: item.price.nickname,
			price: item.amount / 100,
			quantity: item.quantity,
		})
	})

	try {
		twq('event', 'tw-nw40a-ocwgd', {
			value: invoice.total / 100,
			currency: invoice.currency.toUpperCase(),
			conversion_id: invoice.id,
			email_address: invoice.customer_email,
			contents: lineItems.map((li) => {
				return {
					content_id: li.variant_id,
					content_name: li.name,
					content_price: li.price,
					num_items: li.quantity,
				}
			}),
		})
	} catch (e) {
		console.log(e)
	}
}

export const friendbuyTracking = (invoice: Stripe.Invoice) => {
	const fby = document.createElement('div')
	fby.setAttribute('id', 'friendbuylandingpage')
	fby.setAttribute('style', 'display: flex; justify-content: center;')

	const ty = document.getElementById('friendbuy-step')
	if (ty) ty.parentNode.insertBefore(fby, ty.nextSibling)

	// Double check that friendbuyAPI is initialized, loading script is included previously
	const friendbuyAPI = window['friendbuyAPI'] || []
	// Merchant ID from Friendbuy Account
	const MERCHANT_ID = 'c2ef4d8f-87a3-4388-8da9-ff2d9ddfe70b'
	friendbuyAPI.merchantId = MERCHANT_ID //Merchant ID must be provided
	friendbuyAPI.push(['merchant', friendbuyAPI.merchantId])
	;(function (f, r, n, d, b, u, y) {
		while ((u = n.shift())) {
			;(b = f.createElement(r)), (y = f.getElementsByTagName(r)[0])
			b.async = 1
			b.src = u
			y.parentNode.insertBefore(b, y)
		}
	})(document, 'script', ['https://static.fbot.me/friendbuy.js', 'https://campaign.fbot.me/' + friendbuyAPI.merchantId + '/campaigns.js'])

	friendbuyAPI.push(['track', 'page', { name: 'Thanks' }]) // Track page call that can be used for PPO targetting.

	const products = [] // Initializing product array to be filled
	invoice.lines.data.forEach((item) => {
		const productId = item.price.metadata?.sku
		products.push({
			sku: productId,
			quantity: item.quantity,
			price: item.amount / 100,
			name: item.price.lookup_key,
		})
	})

	friendbuyAPI.push([
		'track',
		'purchase',
		{
			id: invoice.id,
			amount: invoice.total / 100,
			currency: invoice.currency.toUpperCase(),
			products: products,
			customer: {
				id: (invoice.customer as Stripe.Customer)?.id,
				email: invoice.customer_email,
			},
			couponCode: formatInvoiceCoupons(invoice),
		},
	])
}

export const getNorthbeamEventData = (invoice: Stripe.Invoice) => {
	const data = {
		id: invoice.id,
		totalPrice: invoice.total / 100, // The total price of the purchase, including shipping, taxes, and discounts.
		shippingPrice: invoice.shipping_cost?.amount_total || 0, // Shipping fees charged to the customer for this purchase.
		taxPrice: isNaN(invoice.tax) || invoice.tax === null ? 0 : invoice.tax, // Taxes charged to the customer for this purchase.
		coupons: [...new Set(invoice.lines.data.flatMap((it) => it.discounts.filter((d: Stripe.Discount) => !!d.coupon).map((d: Stripe.Discount) => d.coupon.id)))], // A comma separated list of discount codes used.
		currency: invoice.currency.toUpperCase(), // A 3-character ISO currency code describing totalPrice, taxPrice, and shippingPrice.
		customerId: (invoice.customer as Stripe.Customer)?.id, // A unique identifier for the customer who made a purchase.
		lineItems: invoice.lines.data.map((it) => {
			const product = it.price.product as Stripe.Product
			return {
				productId: product.id,
				variantId: it.price.lookup_key,
				productName: product.name,
				variantName: it.price.nickname,
				price: it.amount / 100,
				quantity: it.quantity,
			}
		}),
	}
	return data
}

export const sendNorthbeamEvent = (invoice: Stripe.Invoice) => {
	let attempts = 10
	const int = setInterval(() => {
		if (window.Northbeam && attempts > 0) {
			const data = getNorthbeamEventData(invoice)
			window.Northbeam.firePurchaseEvent(data)
			clearInterval(int)
			attempts = 0
		} else {
			attempts = attempts - 1
			if (attempts <= 0) {
				clearInterval(int)
			}
		}
	}, 1000)
}

export const shopMyIntegration = (invoice: Stripe.Invoice) => {
	const cookies = document.cookie.split(';').reduce((res, cookie) => ({ ...res, [(cookie.split('=')[0] || '').trim()]: (cookie.split('=')[1] || '').trim() }), {}) as any
	const { sms_click_id, sms_click_time } = cookies

	const code = formatInvoiceCoupons(invoice)
	if (sms_click_id || code) {
		fetch(`https://api.shopmy.us/api/order_confirmation`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				orderAmount: invoice.total / 100,
				orderId: invoice.id,
				clickId: sms_click_id,
				currency: invoice.currency.toUpperCase(),
				page_url: window.location.href,
				code,
			}),
		})
			.then((r) => r.json())
			.then(() => {
				const now = new Date()
				document.cookie = `sms_click_id=;expires=${now.toUTCString()};path=/;`
				document.cookie = `sms_click_time=;expires=${now.toUTCString()};path=/;`
			})
	}
}

export const trackStripePostPurchaseEvents = (invoice: Stripe.Invoice, fromWebview: boolean) => {
	if (window.location.href.includes('localhost')) return

	fetch('/api/view_invoice/', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ invoiceId: invoice.id }),
	}).catch((e) => console.log('Error tracking post purchase event API call to view_invoice', e))

	try {
		amplitudeThankYou(invoice)
		amCompletePaymentMethod('stripe') // why do we need this? cant we just pull from stripe backend?
		if (fromWebview) amCompleteWebviewPurchase(invoice.id)
	} catch (error) {
		console.log('Error tracking post purchase AM event', error)
	}

	try {
		klaviyoTrackStripePurchaseComplete(invoice.customer_email, invoice.id)
	} catch (error) {
		console.log('Error tracking post purchase Klaviyo event', error)
	}

	try {
		sendNorthbeamEvent(invoice)
	} catch (error) {
		console.log('Error tracking post purchase Northbeam event', error)
	}

	try {
		if (window['dataLayer']) {
			const lineItems = []
			window['dataLayerObj'] = {}

			window['accessoriesProductIds'] = stripeAccessoryIds
			window['podThermoCoverIds'] = stripePodCoverIds
			window['podProductIds'] = stripeMattressIds
			window['podProProductIds'] = []
			window['podProCoverUpgradeIds'] = stripePodCoverUpgradeIds

			for (const line of invoice.lines.data) {
				const isAccessory = stripeAccessoryIds.includes(line.price.id)
				const isThermoCover = stripePodCoverIds.includes(line.price.id)
				const isProCoverUpgrade = stripePodCoverUpgradeIds.includes(line.price.id)

				if (isAccessory) {
					window.dataLayer.push({ purchased_accessory: true })
					window['dataLayerObj']['purchased_accessory'] = true
				} else if (isThermoCover) {
					window.dataLayer.push({ purchased_thermo_cover: true })
					window['dataLayerObj']['purchased_thermo_cover'] = true
				} else if (isProCoverUpgrade) {
					window.dataLayer.push({ purchased_pro_cover_upgrade: true })
					window['dataLayerObj']['purchased_pro_cover_upgrade'] = true
				}

				lineItems.push({
					product_id: line.price.id,
					name: line.description,
					sku: line.price.metadata?.sku,
					variant: line.price.nickname,
					price: line.price.unit_amount / 100,
					quantity: line.quantity,
				})
			}

			const customer_shipping = invoice.customer_shipping || (invoice.customer as Stripe.Customer)
			window['dataLayerObj'] = Object.assign(window['dataLayerObj'], {
				checkout_id: invoice.id,
				order_id: invoice.id,
				revenue: invoice.total / 100,
				total_price: invoice.total / 100,
				// 'tax': {{ checkout.tax_price }} / 100,
				shipping: 0,
				coupon: formatInvoiceCoupons(invoice), // A comma separated list of discount codes used.,
				customer_email: invoice.customer_email,
				customer_first_name: customer_shipping.name.split(' ')[0],
				customer_last_name: customer_shipping.name.split(' ')[1],
				customer_phone: customer_shipping.phone,
				customer_country: customer_shipping.address?.country,
				customer_state: customer_shipping.address?.state,
				customer_city: customer_shipping.address?.city,
				customer_street: customer_shipping.address?.line1,
				customer_zip_code: customer_shipping.address?.postal_code,
				is_shopify_purchase: false,
				line_items: lineItems,
				currency: invoice.currency.toUpperCase(),
			})

			window.dataLayer.push(window.dataLayerObj)

			window.dataLayer.push({ event: 'shopify.checkout_complete' })
			window.dataLayer.push({ event: 'shopify.stripe_signup' })
			const isNewCustomer = !localStorage['firstInvoiceId'] || localStorage['firstInvoiceId'] === invoice.id
			amCompletePaymentMethod('gtm')
			window.dataLayer.push({
				event: 'orderComplete',
				orderTotalValue: invoice.total / 100,
				orderSubTotalValue: invoice.subtotal / 100,
				orderCurrency: invoice.currency,
				orderId: invoice.id,
				new_customer: isNewCustomer,
			})

			// still need to load the widget properly and loop through the answers when submitted / send line item data
		}
	} catch (error) {
		console.log('Error tracking post purchase GTM event', error)
	}

	friendbuyTracking(invoice)
	twitterPurchaseTracking(invoice)
	if (invoice.metadata.subscription) affirmTracking(invoice)

	shopMyIntegration(invoice)
}

export const currencyIsTaxed = (currency: string) => {
	return currency === 'USD' || currency === 'CAD'
}

export const commitTaxTransaction = async (baseUrl: string, invoice: Stripe.Invoice) => {
	if (!invoice) {
		return false
	}

	if (!currencyIsTaxed(invoice.currency.toUpperCase())) {
		return false
	}

	// Price metadata should have code
	const transactionCode = invoice.metadata['transaction_code']

	if (!transactionCode) {
		return false
	}

	try {
		const rawResponse = await fetch(baseUrl + '/api/finalize_tax/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ transactionCode }),
		})

		const json = await rawResponse.json()
		return json
	} catch (e) {
		return {
			success: false,
			erorr: `An error occured saving commiting tax transaction to Avalara: ${e.message}`,
		}
	}
}

export const getTaxObject = async (shippingAddress: FullStripeAddress, invoice: Stripe.UpcomingInvoice, customerID: string) => {
	if (!invoice) {
		return null
	}

	const taxData = getTaxData(shippingAddress, invoice.currency, invoice.lines.data, customerID)
	try {
		if (invoice && taxData && shippingAddress) {
			const res = await fetch('/api/stripe_calculate_tax/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(taxData),
			})

			const resJSON = await res.json()
			datadogReportAPICall(res.status, 'stripe_calculate_tax')
			datadogReportEvent('success', 'stripe_calculate_tax')

			if (resJSON.success) {
				return resJSON.result
			}
		}
	} catch (error) {
		datadogReportEvent('failure', 'stripe_calculate_tax', error)
		throw new Error('Error calculating tax')
	}

	return null
}

export const shouldShowDiscount = (code: string) => {
	return !NON_DISPLAYING_COUPONS.includes(code)
}

export const shouldShowDiscountInSummary = (code: string) => {
	if (code.startsWith('COVER') || code.startsWith('ULTRA') || code.startsWith('PULTRA') || code.startsWith('MATTRESS') || code.startsWith('ACCESSORIES') || code.startsWith('BASEUPGRADE')) {
		return false
	}

	return true
}

export const stateIsNotAllowed = (state: string) => {
	const forbiddenStates = [
		'AK', // Alaska
		'AS', // American Samoa
		'AA', // Armed Forces Americas
		'AE', // Armed Forces Europe
		'AP', // Armed Forces Pacific
		'FM', // Federated States of Micronesia
		'GU', // Guam
		'HI', // Hawaii
		'MH', // Marshall Islands
		'MP', // Northern Mariana Islands
		'PW', // Palau
		'PR', // Puerto Rico
		'VI', // Virgin Islands
	]

	return forbiddenStates.includes(state.toUpperCase())
}
export const unsupportedEUKlarnaCountry = (country: string) => {
	const unsportedCountry = [
		'BG', //Bulgaria
		'HR', //Croatia
		'EE', //Estonia
		'HU', //Hungary
		'LV', //Latvia
		'LT', //Lithuania
		'LU', //Luxemburg
		'RO', //Romania
		'SK', //Slovakia
		'SI', //Slovenia
	]
	return unsportedCountry.includes(country.toUpperCase())
}
